const useStrapiAPI = async <T>(url: string | (() => string), options?: object) => {
  const config = useRuntimeConfig()
  const controller = new AbortController()
  const signal = controller.signal
  const timeout = setTimeout(() => controller.abort(), config.public.API_TIMEOUT)

  return useFetch<T>(url, {
    baseURL: config.public.STRAPI_BASE_URL,
    signal,
    async onRequest({ options }) {
      options.headers = new Headers(options.headers)
      options.headers.append('Authorization', `bearer ${config.public.STRAPI_TOKEN}`)
    },
    async onRequestError({ error }) {
      clearTimeout(timeout)
      throw error
    },
    async onResponse() {
      clearTimeout(timeout)
    },
    async onResponseError({ request, response }) {
      clearTimeout(timeout)
      console.error('[fetch response error]', request, response.status, response.body)
    },
    ...options,
  })
}

export default useStrapiAPI
